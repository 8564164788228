import { v4 as uuidv4 } from "uuid";

// initial state 
export const _initialState = {
    id: null,
    name: "",
    forms: [ 
      {
        platform: "Google Analytics",
        visualization: "table",
        dimensions: [],
        metrics: [],
        selectedDimensionsRows: [],
        selectedDimensionsColumns: [],
        selectedBreakdowns: [],
        selectedMetrics: [],
        filters: [],
      }
    ],
    segments: [],
    selectedSegments: [],
    selectedForm: 0
  };
  
  /*
   * OBJECTS
   */
  const _Explore = (__prevState = _initialState, __action) => {

    /*
     * Switch case for handling actions on
     * explore store.
     */
    switch (__action.type) {
      case "EXPLORE_UPDATE":
        // Return combined state.
        return {
          ...__prevState,
          ...__action.Explore,
        };

      case "EXPLORE_CLEAR":
        // Return combined state.
        return {
          ..._initialState,
        };

      default:
        // Return old state.
        return __prevState;
    }
  }
  
  /*
   * EXPORTS
   */
  export default _Explore;
  