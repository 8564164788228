/*
 * JS
 */
;(() => {
  const _EmailJs = require('@emailjs/browser') // Npm: email utility module.

  /*
   * CONFIGURATION
   */
  _EmailJs.init(process.env.REACT_APP_EMAIL_JS)

  /*
   * CLASS
   */
  class ConcurrentTask {
    // Constructor.
    constructor(taskPromisesFunc = [], batchSize = 1) {
      // Const assignment.
      this.batchSize = batchSize > taskPromisesFunc.length ? taskPromisesFunc.length : batchSize
      this.todoTasks = taskPromisesFunc
      this.resolvedValues = []
    }

    /*
     * Method to execute resolve and reject
     * methods of given promise.
     */
    run(resolve, reject) {
      // Loop over methods size to execute on.
      if (0 < this.todoTasks.length) {
        /*
         * Splice given promise into chunk say.
         * if there is batch size of 2 then two.
         * function will execute at once.
         */
        const taskPromises = this.todoTasks.splice(0, this.batchSize)

        // Run promise for given method at given position.
        Promise.all(taskPromises.map(p => p()))
          .then(resolvedValues => {
            // Update resolved values.
            this.resolvedValues = [...this.resolvedValues, ...resolvedValues]

            // Run resolve or reject condition.
            this.run(resolve, reject)
          }).catch(error => reject(error))
      } else {
        // Resolve give values.
        resolve(this.resolvedValues)
      }
    }

    // Return single promise instead of multiple.
    runTasks() {
      // Return promise.
      return new Promise((resolve, reject) => {
        this.run(resolve, reject)
      })
    }
  }

  // Array prototype extends
  Array.indexestr = (__arr, __val) => {
    // Local variable.
    let _i

    // Const assignment.
    const _indexes = []

    // Variable assignment.
    _i = -1

    /*
     * Consider doing only if __arr is arrays
     * nothing else.
     */
    if (__arr.constructor !== Array) return __arr

    // While indexes count
    while (-1 !== (_i = __arr.indexOf(__val, _i + 1))) {
      // Push index
      _indexes.push(_i)
    }

    // Return indexes
    return _indexes
  }

  // Object prototype extends
  Object.dot = __object => ({
    get: (__path, __defaultValue) => __path.split('.').reduce((o, p) => (o ? o[p] : __defaultValue), __object),
    // eslint-disable-next-line no-cond-assign
    set: (__path, __value) => __path.split('.').reduce((o, p, i) => (o[p] = (__path.split('.').length += i) ? __value : o[p] || {}), __object)
  })
  Object.depthOf = __object => {
    // Local variable.
    let _jamaica, _level

    // Variable assignment.
    _level = 1

    // Loop over object properties.
    for (_jamaica in __object) {
      // HasOwnProperty check.
      if (!__object.hasOwnProperty(_jamaica)) continue

      /*
       * Only continue if given object value
       * is typeof object.
       */
      if ('object' === typeof __object[_jamaica]) {
        // Const assignment.
        const depthBy = 1
        const _depth = Object.depthOf(__object[_jamaica]) + depthBy

        // Variable assignment.
        _level = Math.max(_depth, _level)
      }
    }

    // Return depth.
    return _level
  }
  Object.filterOut = (__obj, __predicate) => {
    // Local variable.
    let _key

    // Const assignment.
    const result = {}

    // Loop over object keys.
    for (_key in __obj) {
      // Conditional checking for objects.
      if (__obj.hasOwnProperty(_key) && __predicate(__obj[_key])) {
        // Push object value to new set.
        result[_key] = __obj[_key]
      }
    }

    // Return result
    return result
  }

  // String prototype extends
  String.toCapitalize = function toCapitalize(__string) {
    // Variable assignment.
    __string = __string ? __string.toLowerCase() : __string

    /*
     * If given string is not empty than use it
     * else prefer this.
     */
    if (__string && 'string' === typeof __string) return __string.charAt(0).toUpperCase() + __string.slice(1)

    // Return capitalize.
    return __string ? this.charAt(0).toUpperCase() : void 0
  }
  String.toCamelCase = __string =>
    // Return replaced string.
    __string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/gu, (__match, __index) => {
      // Return string on null match
      if (0 === Number(__match)) return ''

      // Return camelcase.
      return 0 === __index ? __match.toLowerCase() : __match.toUpperCase()
    })
  String.random = length => {
    // Local variable.
    let _result, i

    // Variable assignment.
    _result = ''

    // Const assignment.
    const _characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const _charactersLength = _characters.length

    // Loop over length and generate string.
    for (i = 0 ;i < length ;i++) {
      // Concat random char at given random index.
      _result += _characters.charAt(Math.floor(Math.random() * _charactersLength))
    }

    // Return result.
    return _result
  }
  String.toChopByGivenLength = (__String, __length) => __String?.length <= __length ? __String : `${__String?.slice?.(0, __length)}...`

  // React.js object extension with useful common functions.
  Object.React = {
    'URLRemoveParams': (url, parameter) => {
      // Better to use l.search if you have a location/link object
      const urlparts = url.split('?')
      if (2 <= urlparts.length) {
        const prefix = `${encodeURIComponent(parameter)}=`

        const pars = urlparts[1].split(/[&;]/g)

        // Reverse iteration as may be destructive
        for (let i = pars.length ;0 < i-- ;) {
          // Idiom for string.startsWith
          if (-1 !== pars[i].lastIndexOf(prefix, 0)) {
            pars.splice(i, 1)
          }
        }

        url = urlparts[0] + (0 < pars.length ? `?${pars.join('&')}` : '')

        return url
      }

      return url
    },
    'ConcurrentTask': ConcurrentTask,
    'SendEmail': __params => _EmailJs.send(process.env.REACT_APP_EMAIL_JS_SERVICE_ID, process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID, __params),
  }
})()
