/*
 * PACKAGES
 */
export const _initialState = {
  isUserLoggedIn: false,
  step: "NONE",
};

/*
 * OBJECTS
 */
const _AccountReducer = (__prevState = _initialState, __action) => {
  // console.log(__action, _initialState, __prevState)
  /*
   * Switch case for handling actions on
   * account store.
   */
  switch (__action.type) {
    case "ACCOUNT_UPDATE":
      // Return combined state.
      return { 
        ...__prevState,
        ...__action.Account,
      };
    case "ACCOUNT_CLEAR":
      // Return combined state.
      return {
        ..._initialState,
      };
    default:
      // Return old state.
      return __prevState;
  }
}

/*
 * EXPORTS
 */
export default _AccountReducer;
