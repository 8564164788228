/*
 * IMPORT
 */
import React, { Suspense } from "react"; // NPM: React package.
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom"; // NPM: React Router Dom for routing.
import { connect } from "react-redux"; // NPM: React Redux for state managment.
import { Flex, Spinner } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast"; // NPM: React Hot Toast for toast messages.

/*
* COMPONENTS
*/
import ScrollToTop from './components/scrollToTop/index';

/*
* AUTH
*/
import Context from "./context";
const SignUp = React.lazy(() => import("./views/auth/signUp/SignUpDefault.js"));
const NewLayout = React.lazy(() => import("layouts/admin"));
const SignIn = React.lazy(() => import("views/auth/signIn/SignInDefault.js"));
const ForgotPassword = React.lazy(() => import("views/auth/forgotPassword/index.js"));
const AccountSettings = React.lazy(() => import("./views/admin/settings/index.js"));

// TOOL Components
const DashboardsDefault = React.lazy(() => import("./views/admin/masterview/index"));
const PaidAds = React.lazy(() => import("./views/admin/paidAds/index"));
const GoogleAds = React.lazy(() => import("./views/admin/googleAds/index"));
const FacebookAds = React.lazy(() => import("./views/admin/facebookAds/index"));
const Seo = React.lazy(() => import("./views/admin/seo/index"));
const Social = React.lazy(() => import('./views/admin/social/index'));
const AdvanceAnalytics = React.lazy(() => import("./views/admin/analytics/index"));
const AnalyticsExplore = React.lazy(() => import("./views/admin/analyticsExplore/index"));
const AdFraud = React.lazy(() => import('./views/admin/adFraud/index'));

// WEBSITE Components
const LandingPage = React.lazy(() => import("./views/admin/website/LandingPage/index"));
const IntegrationPage = React.lazy(() => import("./views/auth/onBoard/integration/index"));
const PrivacyPolicy = React.lazy(() => import("./views/admin/website/privacyPolicy/index"));
const AccountSelect = React.lazy(() => import("./views/auth/onBoard/integration/selectAccount"));
const whatsNext = React.lazy(() => import("./views/auth/onBoard/integration/whatsNext"));
const Gmb = React.lazy(() => import('./views/admin/gmb/index'));
const NotFound = React.lazy(() => import('./views/admin/website/notFound/index'));
const AboutUs = React.lazy(() => import('./views/admin/website/aboutUs/index'));
const FeaturesPage = React.lazy(() => import('./views/admin/website/features/index'));
const IntegrationPageWebsite = React.lazy(() => import('./views/admin/website/integration/index'));
const ContactUsPage = React.lazy(() => import('./views/admin/website/contactUs/index'));
const PricingPage = React.lazy(() => import('./views/admin/website/pricing/index'));
const CaseStudy = React.lazy(() => import('./views/admin/website/caseStudy/index'));
const TermsAndConditions = React.lazy(() => import('./views/admin/website/termsAndConditions/index'));
const ThankYouPage = React.lazy(() => import('./views/admin/website/thankYouPage/index'));
const AgencyOrIndividualDecider = React.lazy(() => import('./views/auth/onBoard/decider/index'));
const IndividualJourney = React.lazy(() => import('./views/auth/onBoard/journeyIndividual/index'));
const AgencyJourney = React.lazy(() => import('./views/auth/onBoard/journeyAgency/index'));
const AddCustomer = React.lazy(() => import('./views/auth/onBoard/addCustomer/index'));

// Deployment

/*
 * OBJECT
 */
const Index = ({ account }) => {

  // Const Assignment
  const _routes = [
    ["/masterview", DashboardsDefault, 1, true],
    ["/setting/:code", AccountSettings, 2, true],
    ["/setting", AccountSettings, 3, true],
    ["/analytics-advance", AdvanceAnalytics, 4, true],
    ["/paid-ads", PaidAds, 5, true],
    ["/paid-google-ads", GoogleAds, 6, true],
    ["/paid-facebook-ads", FacebookAds, 7, true],
    ["/organic-seo", Seo, 8, true],
    ["/analytics-explore", AnalyticsExplore, 9, true],
    ["/organic-google-business-profile", Gmb, 10, true],
    ["/organic-social", Social, 11, true],
    ["/ad-fraud", AdFraud, 12, true],
  ];
  const [context, setContext] = React.useState({});

  return (
    <Context.Provider value={{ context, setContext }}>
      <Toaster />
      <Router>
        <ScrollToTop />
        <Suspense fallback={<Flex w='100vw' h='100vh'><Spinner color="#FFDEC7" mx='auto' my='auto' /></Flex>}>
        <Switch>

           {/* WEBSITE ROUTES */}
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/features" component={FeaturesPage} />
          <Route exact path="/integrations" component={IntegrationPageWebsite} />
          <Route exact path="/contact-us" component={ContactUsPage} />
          <Route exact path="/pricing" component={PricingPage} />
          <Route exact path="/case-studies" component={CaseStudy} />
          <Route exact path="/book-demo/thank-you" component={ThankYouPage} />
          <Route exact path="/terms-and-conditions" component={TermsAndConditions} />

          {/* IF USER IS NOT LOGGED IN */}
          {account?.step === "NONE" &&
            <Switch>
               <Route exact path="/signup" component={SignUp} />
               <Route exact path="/signin" component={SignIn} />
               <Route exact path="/forget-password" component={ForgotPassword} />

                {/* Catch-all Redirect for any other route */}
               <Redirect to="/signin" />
            </Switch>
          }

          {/* STEP ONE */}
          {account?.step === "STEP_ONE" &&
            <Switch>
              <Route exact path="/on-boarding" component={AgencyOrIndividualDecider} />
              <Route exact path="/on-boarding/individual" component={IndividualJourney} />
              <Route exact path="/on-boarding/agency" component={AgencyJourney} />

               {/* Catch-all Redirect for any other route */}
              <Redirect to="/on-boarding" />
              <Redirect from="/signin" to='on-boarding' />
              <Redirect from="/signup" to='on-boarding' />
            </Switch>
          }

          {/* STEP TWO */}
          {account?.step === "STEP_TWO" &&
            <Switch>
               <Route exact from="/on-boarding/integration/whats-next" component={whatsNext}/>
               <Route exact from="/on-boarding/integration/select-account" component={AccountSelect} />
               <Route exact from="/on-boarding/integration" component={IntegrationPage}/>
               <Route exact from="/add-customer" component={AddCustomer} />
               <Route exact from="/on-boarding/integration/whats-next" component={whatsNext}/>
               <Route exact from="/on-boarding/integration/select-account" component={AccountSelect} />
               <Route exact from="/on-boarding/integration" component={IntegrationPage}/>
               {_routes.map((i) => (
                 <Route
                   exact
                   path={i[0]}
                   render={(props) => { const Component = i[1]; return ( <NewLayout> <Component {...props} /> </NewLayout>);}}
                   key={i[2]}
                 />
               ))}

               {/* Catch-all Redirect for any other route */}
               <Redirect exact from="/on-boarding" to="/masterview" />
               <Redirect exact from="/on-boarding/individual" to="/masterview" />
               <Redirect exact from="/on-boarding/agency" to="/add-customer" />
               <Redirect from="/signin" to='masterview' />
               <Redirect from="/signup" to='masterview' />
               <Redirect to="/signin" />
            </Switch>
          }

           {/* IF NO ROUTE IS AVAILABLE SHOW 404 PAGE */}
          <Route component={NotFound} />

        </Switch>
        </Suspense>
      </Router>
    </Context.Provider>
  );
};

/*
* REDUX
*/
const _MapStateToProps = (__state) => ({ account: __state.Account });
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

/*
* EXPORT
*/
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
