import { mode } from "@chakra-ui/theme-tools";
export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        borderRadius: "16px",
        boxShadow: "unset",
        transition: ".25s all ease",
        boxSizing: "border-box",
        _focus: {
          boxShadow: "none",
        },
        _active: {
          boxShadow: "none",
        },
      },
      variants: {
        sorting: (props) => ({
          bg: mode("none")(props),
          color: "white",
          height: "14px",
          _focus: {
            bg: mode("none")(props),
          },
          _active: {
            bg: mode("none")(props),
          },
          _hover: {
            bg: mode("none")(props),
          },
        }),
        outline: () => ({
          borderRadius: "16px",
        }),
        brand: (props) => ({
          bg: mode("brand.500", "brand.400")(props),
          color: "white",
          _focus: {
            bg: mode("brand.500", "brand.400")(props),
          },
          _active: {
            bg: mode("brand.500", "brand.400")(props),
          },
          _hover: {
            bg: mode("brand.600", "brand.400")(props),
          },
        }),
        mediotix: (props) => ({
          fontSize: "clamp(12px, 1.5vw, 15px)",
          bg: mode( "#FF7D63")(props),
          color: "white",
          _focus: {
            outline: "1px solid #FF7D62",
            bg: mode("#FF7D63")(props),
          },
          _active: {
            bg: mode("#FF7D63")(props),
          },
          _hover: {
           
            bg: mode("#fc917e")(props),
          },
        }),
        unstyled: (props) => ({
          fontSize: "clamp(12px, 1.5vw, 15px)",
          bg: mode( "none")(props),
          color: "#2B3674",
          _focus: {

            bg: mode("none")(props),
          },
          _active: {
            bg: mode("none")(props),
          },
          _hover: {
           
            bg: mode("none")(props),
          },
        }),
        blank: (props) => ({
          fontSize: "clamp(12px, 1.5vw, 15px)",
          bg: mode( "none")(props),
          color: "#2B3674",
          _focus: {

            bg: mode("none")(props),
          },
          _active: {
            bg: mode("none")(props),
          },
          _hover: {
           
            bg: mode("none")(props),
          },
        }),
        
        mediotixGradient: (props) => ({
          fontSize: "clamp(12px, 1.5vw, 15px)",
          py: "1.6rem",
          fontWeight: "500",
          boxShadow: "2px 4px 13px 0px rgba(174, 1, 1, 0.36)",
          // paddingTop: "1rem",
          // paddingBottom: "1rem",
          borderRadius:"10px" ,
          color:'white',
          //  padding:'3rem',
          // display: 'inline-block',
          bg: mode("linear-gradient(90deg, #FFB176 0%, #FF9998 100%)")(props),
          
          _focus: {
            // bg: mode("inear-gradient(90deg, #FFB176 0%, #FF9998 100%)")(props),
          },
          _active: {
            // bg: mode("inear-gradient(90deg, #FFB176 0%, #FF9998 100%)")(props),
          },
          _hover: {
            
            bg: mode("linear-gradient(180deg, #FFB176 0%, #FF9998 100%)")(props),
          },
        }),
        mediotixGradientLight: (props) => ({
          fontSize: "clamp(12px, 1.5vw, 15px)",
          py: "1.6rem",
          boxShadow : 'md',
          // paddingTop: "1rem",
          // paddingBottom: "1rem",
          borderRadius:"12px" ,
          color:'white',
          //  padding:'3rem',
          // display: 'inline-block',
          bg: mode("linear-gradient(90deg, #FFF7F1 0%, #FFF2F3 100%)")(props),
          
          _focus: {
            // bg: mode("inear-gradient(90deg, #FFB176 0%, #FF9998 100%)")(props),
          },
          _active: {
            // bg: mode("inear-gradient(90deg, #FFB176 0%, #FF9998 100%)")(props),
          },
          _hover: {
            
            bg: mode("linear-gradient(180deg, #FFF7F1 0%, #FFF2F3 100%)")(props),
            boxShadow: 'lg'
          },
        }),
        gradientButton: (props) => ({
          fontSize: "clamp(12px, 1.5vw, 15px)",
          py: "1.7rem",
          borderRadius:"8px" ,
          color:'white',
          display: "flex",
          bg: mode("linear-gradient(180deg, #FFB176 0%, #FF9998 100%)")(props),
          
          _focus: {
            outline: mode("2px solid #FFB176"),
          
          },
          _active: {
            // outline: 'none',
          },
          _hover: {
            
            bg: mode("linear-gradient(90deg, #FFB176 0%, #FF9998 100%)")(props),
          },
        }),
        orangeTinyButton: (props) => ({
          fontSize: "clamp(12px, 1.5vw, 15px)",
          width: "auto",
          py: "1rem",
          borderRadius:"5px" ,
          color:'white',
          display: "flex",
          bg: mode("linear-gradient(180deg, #FFB176 0%, #FF9C94 100%)")(props),
          
          _focus: {
            outline: mode("2px solid #FFB176"),
          
          },
          _active: {
            // outline: 'none',
          },
          _hover: {
            
            bg: mode("linear-gradient(90deg, #FFB176 0%, #FF9C94 100%)")(props),
          },
        }),
        greenTinyButton: (props) => ({
          fontSize: "clamp(12px, 1.5vw, 15px)",
          width: "auto",
          py: "1rem",
          borderRadius:"5px" ,
          color:'white',
          display: "flex",
          bg: mode("linear-gradient(180deg, #63DED0 0%, #00CDB0 100%)")(props),
          
          _focus: {
            outline: mode("2px solid #FFB176"),
          
          },
          _active: {
            // outline: 'none',
          },
          _hover: {
            
            bg: mode("linear-gradient(90deg, #63DED0 0%, #00CDB0 100%))")(props),
          },
        }),
        whiteButton: (props) => ({
          fontSize: "clamp(12px, 1.5vw, 15px)",
          py: "1.7rem",
          borderRadius:"8px" ,
          color: "#F2905F",
          // display: "flex",
          bg: mode("white")(props),
          
          _focus: {
            outline: mode("2px solid white"),
          
          },
          _active: {
            // outline: mode('10px solid white'),
          },
          _hover: {
            boxShadow: '0px 4px 40px 0px rgba(0, 0, 0, 0.08)'

          },
        }),
        darkBrand: (props) => ({
          fontSize: "clamp(12px, 1.5vw, 15px)",
          bg: mode("brand.900", "brand.400")(props),
          color: "white",
          _focus: {
            bg: mode("brand.900", "brand.400")(props),
          },
          _active: {
            bg: mode("brand.900", "brand.400")(props),
          },
          _hover: {
            bg: mode("brand.800", "brand.400")(props),
          },
        }),
        lightBrand: (props) => ({
          bg: mode("#F2EFFF", "whiteAlpha.100")(props),
          color: mode("brand.500", "white")(props),
          _focus: {
            bg: mode("#F2EFFF", "whiteAlpha.100")(props),
          },
          _active: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _hover: {
            bg: mode("secondaryGray.400", "whiteAlpha.200")(props),
          },
        }),
        light: (props) => ({
          bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          color: mode("secondaryGray.900", "white")(props),
          _focus: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _active: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _hover: {
            bg: mode("secondaryGray.400", "whiteAlpha.200")(props),
          },
        }),
        action: (props) => ({
          fontWeight: "500",
          borderRadius: "50px",
          bg: mode("secondaryGray.300", "brand.400")(props),
          color: mode("brand.500", "white")(props),
          _focus: {
            bg: mode("secondaryGray.300", "brand.400")(props),
          },
          _active: { bg: mode("secondaryGray.300", "brand.400")(props) },
          _hover: {
            bg: mode("secondaryGray.200", "brand.400")(props),
          },
        }),
        setup: (props) => ({
          fontWeight: "500",
          borderRadius: "50px",
          bg: mode("transparent", "brand.400")(props),
          border: mode("1px solid", "0px solid")(props),
          borderColor: mode("secondaryGray.400", "transparent")(props),
          color: mode("secondaryGray.900", "white")(props),
          _focus: {
            bg: mode("transparent", "brand.400")(props),
          },
          _active: { bg: mode("transparent", "brand.400")(props) },
          _hover: {
            bg: mode("secondaryGray.100", "brand.400")(props),
          },
        }),
      },
    },
  },
};
