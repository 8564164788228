// reducers.js
import { addDays, sub, subDays } from "date-fns";

export const initialState = {
    selection: {
        startDate: subDays(new Date(), 30),
        endDate: subDays(new Date(), 1),
        key: "selection",
      },
  
      compare: {
        startDate: subDays(new Date(), 61),
        endDate: subDays(new Date(), 31),
        key: "compare",
      },
  };

   const _DateReducer = (__prevState = initialState, __action) => {
    switch (__action.type) {
      case 'UPDATE_DATE_SELECTION':
        return { ...__prevState, selection: __action.ReduxDateRange };
      case 'UPDATE_DATE_COMPARE':
        return { ...__prevState, compare: __action.ReduxDateRange };
      case 'CLEAR_DATE_RANGE':
        return { ...initialState };
      default:
        return __prevState;
    }
  };


  export default _DateReducer;
