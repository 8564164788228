/*
 * IMPORT
 */
import React from "react"; // Npm: React.
import { hydrate, render } from "react-dom";
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client' // Npm: Apollo client for handling graphql.
import { createUploadLink } from 'apollo-upload-client' // Npm: Apollo client upload link.
import App from 'index.app.js'; // App component.
import { Provider } from 'react-redux' // Npm: Redux provider.
import { PersistGate } from 'redux-persist/integration/react' // Npm: Redux persist gate.
import { ChakraProvider } from "@chakra-ui/react"; // Npm: Chakra UI provider.
import * as Sentry from "@sentry/react"; // NPM: Sentry for error handling .
import { Analytics } from "@vercel/analytics/react" // vercel web analytics
/*
 * PACKAGES
 */
import './iife'
import Redux, { Persist } from './redux'
/*
 * STYLES
 */
import theme from "theme/theme";
import "assets/css/App.css";
import "mapbox-gl/dist/mapbox-gl.css";


Sentry.init({
  dsn: "https://1e6867f2f03e97a780fce9f4aeecb5ac@o130476.ingest.sentry.io/4506294014246912",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://mx360.io/"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/*
 * APOLLO CLIENT PROVIDER
 */
const ApolloClientProvider = initialState => {
  // const state = Redux.getState()
  // const token = state.auth.token
  const _NodeBackendHttpLink = ApolloLink.from([
    createUploadLink({
      uri: process.env.REACT_APP_NODE_BACKEND
    })
  ]);

  const _ApolloClient = new ApolloClient({
    connectToDevTools: false,
    ssrMode: true,
    link: ApolloLink.from([_NodeBackendHttpLink, _NodeBackendHttpLink]),
    cache: new InMemoryCache().restore(initialState || {}),
    defaultOptions: {
      query: {
        context: {
          clientName: 'default'
        }
      },

      mutate: {
        context: {
          clientName: 'default'
        }
      },
      watchQuery: {
        context: {
          clientName: 'default'
        }
      }
    }
  });
  return _ApolloClient;
}

/*
 * RENDER
 */

const Root = () => {

  return (
    <Provider store={Redux}>
      <PersistGate persistor={Persist}>
        <ChakraProvider theme={theme}>
          <ApolloProvider client={ApolloClientProvider()}>
              <Analytics/>
              <App />
          </ApolloProvider>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  );
};

// Initialize dataLayer
window.dataLayer = window.dataLayer || [];

// ReactDOM.render( <Root />, document.getElementById("root"));

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}